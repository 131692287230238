<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>教研中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/evaluation/details/' + $route.params.id }">测评详情</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="180px" size="mini">
                <el-form-item label="年级：" label-position="left" class="input" prop="grade">
                    <!-- <el-input style="width: 200px" v-model=""></el-input> -->
                    <el-select v-model="form.grade" clearable placeholder="选择分类" size="small">
                        <el-option v-for="item in grades" :key="item.id" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择题库: " prop="question_bank_id">
                    <select-question-bank @onSelect="onSelectItem" @onDelete="onDeleteItem"></select-question-bank>
                </el-form-item>

                <el-form-item label="是否包含阅读材料: " prop="has_read_content">
                    <el-switch v-model="form.has_read_content" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>

                <el-form-item label="阅读材料内容：" label-position="left" prop="read_content" v-if="form.has_read_content">
                    <editor @onChange="onChange" :content="form.read_content"></editor>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="checkOnSubmit">保存</el-button>
                    <el-button style="margin-left: 10px" @click="$router.push('/evaluation/details/' + $route.params.id)"
                        size="small">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import selectQuestionBank from '@/components/selectQuestionBank'
import Editor from "@/components/editor";
export default {

    name: 'Add',
    components: {
        selectQuestionBank,
        Editor
    },
    data() {
        return {
            grades: [
                {
                    id: 1,
                    name: '一年级'
                },
                {
                    id: 2,
                    name: '二年级'
                },
                {
                    id: 3,
                    name: '三年级'
                },
                {
                    id: 4,
                    name: '四年级'
                },
                {
                    id: 5,
                    name: '五年级'
                },
                {
                    id: 6,
                    name: '六年级'
                },
                {
                    id: 7,
                    name: '七年级'
                },
                {
                    id: 8,
                    name: '八年级'
                },
                {
                    id: 9,
                    name: '九年级'
                },
            ],
            form: {
                has_read_content: true,
                question_bank_id: null,
                read_content: ''
            },
            rules: {
                grade: [{ required: true, message: '请输入年级', trigger: 'blur' },],
                question_bank_id: [
                    { required: true, message: '请选择题库', trigger: 'change' }
                ],
                read_content: [{ required: true, message: '请输入阅读材料内容', trigger: 'blur' },],
            },
            loading: false
        }
    },
    methods: {
        ...mapActions('evaluation', ['addEvaluationItem']),
        checkOnSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.add()
                }
            });
        },
        onChange(content) {
            this.form.read_content = content
        },
        async add() {
            this.loading = true
            const { res_info } = await this.addEvaluationItem(this.form)
            this.loading = false
            if (res_info != 'ok') return
            this.$message.success('新增成功！')
            this.$router.push('/evaluation/details/' + this.$route.params.id)
        },
        // 题库/批注
        onSelectItem(id) {
            this.form.question_bank_id = id
        },
        onDeleteItem() {
            this.form.question_bank_id = ''
        }
    },
    mounted() {
        this.form.evaluations_id = this.$route.params.id;
    },
}
</script>


